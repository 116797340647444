import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { LoadingPage, Seo, Text } from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import { tablet, useQuery } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import Checklist from './components/Checklist';
import { normalizeStates } from 'utils/localization';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 2rem 1rem;
  @media ${tablet} {
    padding: 1.5rem 1rem 3.5rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`;

const ImageWrapper = styled.div`
  width: 11.25rem;
  height: 11.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 1.5rem;
  svg {
    width: 11.25rem;
    height: 11.25rem;
  }
`;

const StyledPercent = styled(Text)`
  position: absolute;
  color: #f27999;
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const LOADING_DURATION = 7500;
const INTERVAL = 75;

const Calculating: FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const data = useQuizData('calculating');
  const { code, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );

  const { goToEmail } = useRouter();

  usePageView({
    client_code: code,
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    email: user?.email,
  });

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0, behavior: 'smooth' });
    }, 10);
  }, [window.location.href]);

  useEffect(() => {
    let tickCount = 0;
    const totalTicks = LOADING_DURATION / INTERVAL;

    setProgress(0);

    const animationInterval = setInterval(() => {
      tickCount += 1;
      const newProgress = Math.min((tickCount / totalTicks) * 100, 100);
      setProgress(newProgress);

      if (tickCount >= totalTicks) {
        clearInterval(animationInterval);
        setIsLoading(false);
      }
    }, INTERVAL);

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const completeTimer = setTimeout(() => {
        goToEmail();
      }, 1000);

      return () => clearTimeout(completeTimer);
    }
  }, [isLoading]);

  if (!data) {
    return <LoadingPage />;
  }

  const { title, list } = data;

  return (
    <>
      <Seo />
      <Header color="light0" hasDivider={true} />
      <ContentContainer>
        <div>
          <ImageWrapper>
            <CircularProgressbar
              value={progress}
              text=""
              styles={buildStyles({
                pathTransitionDuration: 0.25,
                pathColor: '#F27999',
                trailColor: '#FAFAFA',
                strokeLinecap: 'round',
              })}
            />
            <StyledPercent>{Math.round(progress)}%</StyledPercent>
          </ImageWrapper>
        </div>
        <Title>{title}</Title>

        <Checklist list={list} onProgress={progress => setProgress(progress)} />
      </ContentContainer>
    </>
  );
};

export default Calculating;
