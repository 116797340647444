import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import CircleTick from 'assets/icons/calculating-circle.svg';

interface ChecklistProps {
  list?: string[];
  onEnd?: () => void;
  onProgress: (progress: number) => void;
}

interface ChecklistItem {
  title: string;
  opacity: number;
  delay: number;
}

const ListContainer = styled.div``;

const ListItemContainer = styled.div<{ opacity: number; delay: number }>`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: opacity 0.5s ease;
  transition-delay: ${({ delay }) => delay}s;
  opacity: ${({ opacity }) => opacity};
`;

const ListTitle = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-left: 0.5rem;
`;

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const Checklist: FC<ChecklistProps> = ({ list }) => {
  const [firstHalfList, setFirstHalfList] = useState<ChecklistItem[]>(
    list?.map((title, index) => ({ title, opacity: 0, delay: index * 1.5 })) ||
      [],
  );

  const handleFirstHalfList = async () => {
    await timeout(50);

    setFirstHalfList(prev => {
      const newList = prev.map(p => ({
        ...p,
        opacity: 1,
      }));
      return newList;
    });

    await timeout(3000);
  };

  useEffect(() => {
    handleFirstHalfList();
  }, []);

  const renderBulletpoints = (
    { title, opacity, delay }: ChecklistItem,
    index: number,
  ) => (
    <ListItemContainer opacity={opacity} delay={delay} key={index}>
      <CircleTick width={24} height={24} />
      <ListTitle>{title}</ListTitle>
    </ListItemContainer>
  );

  return <ListContainer>{firstHalfList.map(renderBulletpoints)}</ListContainer>;
};

export default Checklist;
